import { useMutation, useQuery } from '@apollo/client';
import { Divider, Stack } from '@mui/material';
import { uniq } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReefNavContext } from '../../../contexts/reefNavContext';
import type { AccountFilterUpdateInput } from '../../../graphql/generated';
import {
  DeleteAccountFilterDocument,
  GetAccountRenewalFiscalsDocument,
  GetClientUsersDocument,
  GetMyFiltersDocument,
  UpdateAccountFilterDocument,
} from '../../../graphql/generated';
import { useAccountIndustries, useAccountNames, useAccountRegions } from '../../../hooks/account';
import { useEditFilter } from '../../../hooks/filters';
import type { FiscalQuarter } from '../../../models/account';
import { AccountFilterType } from '../../../models/filters';
import { ResultType } from '../../../models/result';
import { filterCustomers$ } from '../../../selectors';
import type { CollapsibleDrawerProps } from '../../ReefNavDrawer/CollapsibleDrawer';
import { CollapsibleDrawer } from '../../ReefNavDrawer/CollapsibleDrawer';
import { FilterAccordion } from './FilterAccordion';
import { FilterAlert } from './FilterAlert';
import { FilterPicker } from './FilterPicker';
import {
  NumberBetweenFilterInput,
  OwnershipFilterInput,
  RenewalQuarterFilterInput,
  StringFilterInput,
  UpcomingRenewalFilterInput,
} from './inputs';

export const FilterEditDrawer = ({ open, onClose, ...drawerProps }: CollapsibleDrawerProps) => {
  const { filterEditMenuState, openMenu } = useReefNavContext();

  const { filterId, editFilterType } = useMemo(() => {
    if (filterEditMenuState.filterId != null) {
      return {
        filterId: filterEditMenuState.filterId,
        editFilterType: filterEditMenuState.editFilterType,
      };
    }
    return {};
  }, [filterEditMenuState]);

  const [updateFilter, updateResult] = useMutation(UpdateAccountFilterDocument);
  const [deleteFilter, deleteResult] = useMutation(DeleteAccountFilterDocument, {
    refetchQueries: [{ query: GetMyFiltersDocument }],
  });

  const filterResult = useEditFilter({ filterId });

  const {
    nameFilter,
    industryFilter,
    primaryOwnerFilter,
    secondaryOwnerFilter,
    renewalOwnerFilter,
    upcomingRenewalFilters,
    renewalQuarterFilters,
    pipelineFilters,
    arrFilters,
    regionFilter,
  } = useMemo(() => {
    if (filterResult.state === ResultType.Value) {
      return filterResult.value;
    }
    return {
      nameFilter: undefined,
      industryFilter: undefined,
      primaryOwnerFilter: undefined,
      secondaryOwnerFilter: undefined,
      renewalOwnerFilter: undefined,
      upcomingRenewalFilters: [],
      renewalQuarterFilters: [],
      pipelineFilters: [],
      arrFilters: [],
      regionFilter: undefined,
    };
  }, [filterResult.state, filterResult.value]);

  const noFiltersApplied = useMemo(
    () =>
      [
        nameFilter,
        industryFilter,
        primaryOwnerFilter,
        secondaryOwnerFilter,
        renewalOwnerFilter,
        upcomingRenewalFilters,
        renewalQuarterFilters,
        pipelineFilters,
        arrFilters,
        regionFilter,
      ].every((filters) => filters == null || (Array.isArray(filters) && filters.length === 0)),
    [
      industryFilter,
      nameFilter,
      pipelineFilters,
      arrFilters,
      primaryOwnerFilter,
      renewalQuarterFilters,
      secondaryOwnerFilter,
      renewalOwnerFilter,
      upcomingRenewalFilters,
      regionFilter,
    ],
  );

  const nav = useNavigate();

  const [filterAlert, setFilterAlert] = useState<string>();

  const handleClose = useCallback(async () => {
    if (filterResult.state === ResultType.Value && filterResult.value.filterName === '') {
      if (noFiltersApplied) {
        await deleteFilter({ variables: { input: { filterId: filterResult.value.id } } });
        nav('/filter-customers');
      } else {
        setFilterAlert('Please choose a name and save this filter.');
        return;
      }
    }
    setFilterAlert(undefined);
    onClose();
  }, [filterResult, onClose, noFiltersApplied, deleteFilter, nav]);

  const update = useCallback(
    async (input: Partial<AccountFilterUpdateInput>) => {
      const result = await updateFilter({
        variables: { input: { filterId, ...input } },
        // we only need to refetch this when the update actually creates a new filter
        refetchQueries: filterId == null ? [{ query: GetMyFiltersDocument }] : undefined,
      });
      if (result.data != null) {
        if (filterId == null) {
          openMenu({
            menu: 'filter-edit',
            filterId: result.data.payload.filter.id,
            editName: true,
          });
        }
        nav(`/filter-customers/${result.data.payload.filter.id}`);
      }
    },
    [filterId, nav, openMenu, updateFilter],
  );

  // options for the various filters
  const industriesResult = useAccountIndustries();
  const accountNamesResult = useAccountNames();
  const usersResult = useQuery(GetClientUsersDocument);
  const renewalYearsResult = useQuery(GetAccountRenewalFiscalsDocument);
  const regionsResult = useAccountRegions();

  const userOptions = useMemo(
    () => usersResult.data?.client.users.map(({ id, name }) => ({ id, label: name })),
    [usersResult.data?.client.users],
  );

  const renewalYears = useMemo(
    () =>
      uniq(
        renewalYearsResult.data?.client.accounts
          .map((a) => a.reefData.renewalFiscalQuarter?.split(' ').at(0))
          .filter((y): y is NonNullable<typeof y> => y != null)
          .map((y) => Number(y))
          .sort((a, b) => a - b) ?? [],
      ),
    [renewalYearsResult.data?.client.accounts],
  );

  return (
    <CollapsibleDrawer
      {...drawerProps}
      data-uid={filterCustomers$.editFilter.drawer.component}
      open={open}
      onClose={handleClose}
    >
      <Stack sx={{ height: '100%' }}>
        <Stack spacing={2} sx={{ mx: 2 }}>
          <FilterPicker
            selectedFilterId={filterId}
            initialName={
              filterResult.state === ResultType.Value ? filterResult.value.filterName : ''
            }
            onSelect={(filterId) => {
              nav(`/filter-customers${filterId != null ? `/${filterId}` : ''}`);
              openMenu({ menu: 'filter-edit', filterId });
              setFilterAlert(undefined);
            }}
            onDelete={async (filterId) => {
              await deleteFilter({ variables: { input: { filterId } } });
              nav('/filter-customers');
              openMenu({ menu: 'filter-edit', filterId: undefined });
              setFilterAlert(undefined);
            }}
            deleteLoading={filterResult.state === ResultType.Loading || deleteResult.loading}
            onRename={async (name) => {
              await update({ name });
              setFilterAlert(undefined);
            }}
            renameLoading={filterResult.state === ResultType.Loading || updateResult.loading}
          />
        </Stack>
        <FilterAlert message={filterAlert} onDismiss={() => setFilterAlert(undefined)} />
        <Stack>
          <Divider />
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.AccountNameContains}
            name="Customer Name"
            numFilters={nameFilter?.value.length ?? 0}
          >
            <StringFilterInput
              type={AccountFilterType.AccountNameContains}
              filter={nameFilter}
              onChange={(customerNames) => update({ customerNames })}
              options={
                accountNamesResult.state === ResultType.Value
                  ? [...accountNamesResult.value]
                  : undefined
              }
              loading={updateResult.loading || accountNamesResult.state === ResultType.Loading}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.PrimaryOwner}
            name="Customer Lead"
            numFilters={primaryOwnerFilter?.owners.length ?? 0}
          >
            <OwnershipFilterInput
              type={AccountFilterType.PrimaryOwner}
              filter={primaryOwnerFilter}
              loading={usersResult.loading || updateResult.loading}
              options={userOptions}
              onChange={(primaryOwnerNames) => update({ primaryOwnerNames })}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.SecondaryOwner}
            name="Sales Lead"
            numFilters={secondaryOwnerFilter?.owners.length ?? 0}
          >
            <OwnershipFilterInput
              type={AccountFilterType.SecondaryOwner}
              filter={secondaryOwnerFilter}
              loading={usersResult.loading || updateResult.loading}
              options={userOptions}
              onChange={(secondaryOwnerNames) => update({ secondaryOwnerNames })}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.RenewalOwner}
            name="Renewal Lead"
            numFilters={renewalOwnerFilter?.owners.length ?? 0}
          >
            <OwnershipFilterInput
              type={AccountFilterType.RenewalOwner}
              filter={renewalOwnerFilter}
              loading={usersResult.loading || updateResult.loading}
              options={userOptions}
              onChange={(renewalOwnerNames) => update({ renewalOwnerNames })}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.IndustryContains}
            name="Industry"
            numFilters={industryFilter?.value.length ?? 0}
          >
            <StringFilterInput
              type={AccountFilterType.IndustryContains}
              filter={industryFilter}
              onChange={(industryNames) => update({ industryNames })}
              options={
                industriesResult.state === ResultType.Value
                  ? [...industriesResult.value]
                  : undefined
              }
              loading={updateResult.loading || industriesResult.state === ResultType.Loading}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={
              editFilterType === AccountFilterType.PipelineAtLeast ||
              editFilterType === AccountFilterType.PipelineAtMost
            }
            name="Pipeline"
            numFilters={pipelineFilters.length}
          >
            <NumberBetweenFilterInput
              filters={pipelineFilters}
              loading={updateResult.loading}
              onChange={(options) => update({ pipelineBetween: options != null ? [options] : [] })}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={
              editFilterType === AccountFilterType.ArrAtLeast ||
              editFilterType === AccountFilterType.ArrAtMost
            }
            name="ARR"
            numFilters={arrFilters.length}
          >
            <NumberBetweenFilterInput
              filters={arrFilters}
              loading={updateResult.loading}
              onChange={(options) => update({ arrBetween: options != null ? [options] : [] })}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.DaysUntilRenewal}
            name="Days Until Renewal"
            numFilters={upcomingRenewalFilters.length}
          >
            <UpcomingRenewalFilterInput
              filter={upcomingRenewalFilters.at(0)}
              loading={updateResult.loading}
              onChange={(days) => update({ renewalDateWithin: days != null ? [days] : [] })}
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.RenewalFiscalQuarter}
            name="Renewal Fiscal Quarter"
            numFilters={renewalQuarterFilters.length}
          >
            <RenewalQuarterFilterInput
              filter={renewalQuarterFilters.at(0)}
              yearOptions={renewalYears}
              loading={updateResult.loading || renewalYearsResult.loading}
              onChange={(filters) =>
                update({
                  renewalQuarter: filters.map((f): FiscalQuarter | `${number}` => {
                    if (f.quarter != null) {
                      return `${f.year} Q${f.quarter}`;
                    }
                    return `${f.year}`;
                  }),
                })
              }
            />
          </FilterAccordion>
          <FilterAccordion
            initiallyOpen={editFilterType === AccountFilterType.RegionContains}
            name="Region"
            numFilters={regionFilter?.value.length ?? 0}
          >
            <StringFilterInput
              type={AccountFilterType.RegionContains}
              filter={regionFilter}
              onChange={(regionNames) => update({ regionNames })}
              options={
                regionsResult.state === ResultType.Value ? [...regionsResult.value] : undefined
              }
              loading={updateResult.loading || regionsResult.state === ResultType.Loading}
            />
          </FilterAccordion>
          <Divider />
        </Stack>
      </Stack>
    </CollapsibleDrawer>
  );
};
