import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license';
import { ErrorBoundary } from '@sentry/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { GlobalErrorFallback } from '../components/GlobalErrorFallback';
import { useCustomTheme } from '../hooks/useCustomTheme';
import { MuiLocalizationProvider } from './MuiLocalizationProvider';
import { PersistenceProvider } from './PersistenceProvider';
import { ReefAnalyticsProvider } from './ReefAnalyticsProvider';
import { ReefApolloProvider } from './ReefApolloProvider';
import { ReefAuthProvider } from './ReefAuthProvider';
import { ReefLDProvider } from './ReefLDProvider';
import { ReefNavProvider } from './ReefNavProvider';
import { UserSettingsProvider } from './UserSettingsProvider';

// lifted from https://github.com/wojtekmaj/react-pdf/issues/1843#issuecomment-2448287627
(async () => {
  const { pdfjs } = await import('react-pdf');
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();
})();

export const ReefContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  if (import.meta.env.VITE_MUI_X_PREMIUM_KEY) {
    LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_PREMIUM_KEY);
  }

  return (
    // error boundary here catches any uncaught errors in the context/internals of router
    <ErrorBoundary fallback={<GlobalErrorFallback />} showDialog>
      <ThemeProvider theme={useCustomTheme()}>
        <ReefLDProvider
          // TODO: we should specify a specific set of flags to load - LD is telling us we are reading
          // flags that we don't actually use anymore
          // flags={someFlags}
          clientSideID={
            import.meta.env.VITE_LAUNCH_DARKLY_APP_KEY ?? 'MISSING_LAUNCH_DARKLY_APP_KEY_ENV_VAR'
          }
        >
          <ReefAnalyticsProvider
            sentryDSN={import.meta.env.VITE_SENTRY_DSN} // do not supply a default value for these envvars
            sentryEnv={import.meta.env.VITE_SENTRY_ENV} // do not supply a default value for these envvars
          >
            <ReefAuthProvider
              userPoolId={import.meta.env.VITE_USER_POOL_ID ?? 'MISSING_USER_POOL_ID_ENV_VAR'}
              webClientId={import.meta.env.VITE_WEB_CLIENT_ID ?? 'MISSING_WEB_CLIENT_ID_ENV_VAR'}
              cognitoCustomDomain={
                import.meta.env.VITE_COGNITO_CUSTOM_DOMAIN ??
                'MISSING_COGNITO_CUSTOM_DOMAIN_ENV_VAR'
              }
              oAuthRedirect={`${window.location.origin}/login`}
            >
              <ReefApolloProvider
                graphqlUri={import.meta.env.VITE_REST_API_URI ?? 'MISSING_REST_API_URI_ENV_VAR'}
              >
                <UserSettingsProvider>
                  <MuiLocalizationProvider>
                    <PersistenceProvider>
                      <ReefNavProvider>{children}</ReefNavProvider>
                    </PersistenceProvider>
                  </MuiLocalizationProvider>
                </UserSettingsProvider>
              </ReefApolloProvider>
            </ReefAuthProvider>
          </ReefAnalyticsProvider>
        </ReefLDProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};
