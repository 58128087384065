import type { ClientMetadata, ISignUpResult, NodeCallback } from 'amazon-cognito-identity-js';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { isObject } from 'lodash-es';

import type { HostedIdentityProvider } from './oauth';
import { OAuth } from './oauth';

export enum CognitoErrorCodes {
  UsernameExistsException = 'UsernameExistsException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException',
  CodeMismatchException = 'CodeMismatchException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  NotAuthorizedException = 'NotAuthorizedException',
  InvalidPasswordException = 'InvalidPasswordException',
}

interface CognitoError extends Error {
  code: CognitoErrorCodes;
  name: CognitoErrorCodes;
}

/**
 * Type-guard for a cognito error code.
 * @param e error to check
 * @returns type guard
 */
export function isCognitoError(e: Error): e is CognitoError {
  return isObject(e) && 'code' in e && typeof e.code === 'string' && e.code in CognitoErrorCodes;
}

interface SignUpOptions {
  /**
   * If provided and true, provides a signal to cognito that this is a skeleton flow.
   */
  isKnock?: boolean;
}

export interface AuthApi {
  /**
   * Signs the user in via a social provider. This sign in method redirects the browser to
   * a cognito url and is redirected back via a callback url.
   */
  federatedSignIn(provider: HostedIdentityProvider, email?: string): Promise<void>;

  /**
   * Authenticates to the identity provider.
   * @param username username
   * @param password password
   * @param clientMetadata clientMetadata
   */
  signIn(username: string, password: string, clientMetadata: ClientMetadata): Promise<CognitoUser>;

  /**
   * Signs a user out of their current session.
   */
  signOut(): Promise<void>;

  /**
   * Gets the user's current session (& handle Amplify refresh
   * under-the-hood).
   */
  getCurrentSession(): Promise<CognitoUserSession | null>;

  /**
   * Force refresh the current user session.
   */
  forceRefreshSession(): Promise<CognitoUserSession>;
  /**
   * Respond to an auth challenge issued to the user.
   * @param username the username to confirm
   * @param code answer to the auth challenge
   * @throws error if confirmation fails
   */
  confirmSignUp(username: string, code: string): Promise<void>;

  /**
   * Resend an auth challenge to a user.
   * @param username username to resend the details to
   * @returns promise resolving to code delivery details
   */
  resendSignUp(username?: string): Promise<void>;

  /**
   * Method to complete a user's registration
   * @param username username
   * @param password password
   * @param options options
   * @returns promise resolving to the sign up result
   */
  signUp(username: string, password: string, options?: SignUpOptions): Promise<ISignUpResult>;
}

interface CognitoAuthOptions {
  userPoolId: string;
  webClientId: string;
  oAuthDomain: string;
  oAuthRedirect: string;
}

export class CognitoAuth implements AuthApi {
  private oauthClient: OAuth;
  private userPool: CognitoUserPool;

  constructor({ userPoolId, webClientId, oAuthDomain, oAuthRedirect }: CognitoAuthOptions) {
    this.userPool = new CognitoUserPool({ UserPoolId: userPoolId, ClientId: webClientId });

    this.oauthClient = new OAuth({
      clientId: webClientId,
      scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      domain: oAuthDomain,
      redirectUri: oAuthRedirect,
    });
    // only handle oauth federation flows if we are on the oauth redirected route
    if (`${window.location.origin}${window.location.pathname}` == oAuthRedirect) {
      this._handleOAuthResponse(oAuthRedirect);
    }
  }

  private get user(): CognitoUser | null {
    return this.userPool.getCurrentUser();
  }

  private async _handleOAuthResponse(redirectUri: string): Promise<void> {
    const url = new URL(window.location.href);
    if (
      !url.searchParams.has('code') &&
      !url.searchParams.has('access_token') &&
      !url.searchParams.has('error')
    ) {
      return;
    }

    try {
      const oauthResult = await this.oauthClient.handleAuthResponse(url);
      if (oauthResult == null) {
        return;
      }
      const { accessToken, idToken, refreshToken } = oauthResult;
      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: idToken }),
        RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
        AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
      });

      const username = session.getIdToken().decodePayload()['cognito:username'];
      const user = new CognitoUser({ Username: username, Pool: this.userPool });
      user.setSignInUserSession(session);

      // reload so we can reinitialize the auth session
      // TODO: there might be a better way to do this without the reload 🤷
      window.open(redirectUri, '_self');
    } catch (err: unknown) {
      window.history.replaceState({ oAuthError: err }, '', redirectUri);
    }
  }

  signUp(
    username: string,
    password: string,
    options?: SignUpOptions | undefined,
  ): Promise<ISignUpResult> {
    const userAttributes: CognitoUserAttribute[] = [
      new CognitoUserAttribute({ Name: 'email', Value: username }),
      new CognitoUserAttribute({ Name: 'name', Value: username }),
    ];
    const validationData: CognitoUserAttribute[] = [];
    const clientMetadata: ClientMetadata = options?.isKnock ? { knock: 'true' } : undefined;

    return new Promise((res, rej) => {
      const cb: NodeCallback<Error, ISignUpResult> = (err, result) => {
        if (err != null) {
          return rej(err);
        }
        if (result == null) {
          return rej(new Error('got invalid empty signup result'));
        }
        return res(result);
      };
      this.userPool.signUp(username, password, userAttributes, validationData, cb, clientMetadata);
    });
  }

  resendSignUp(username?: string): Promise<void> {
    return new Promise((res, rej) => {
      let user = this.user;
      if (user == null) {
        if (username == null) {
          throw new Error('no username provided for resend confirmation code');
        }
        user = new CognitoUser({ Username: username, Pool: this.userPool });
      }
      return user.resendConfirmationCode((err) => {
        if (err != null) {
          return rej(err);
        }
        return res();
      });
    });
  }

  federatedSignIn(provider: HostedIdentityProvider, email?: string): Promise<void> {
    return this.oauthClient.oAuthSignIn(provider, email);
  }

  signIn(
    username: string,
    password: string,
    clientMetadata?: ClientMetadata,
  ): Promise<CognitoUser> {
    if (this.user?.getSignInUserSession() != null) {
      throw new Error('there is already a user signed in');
    }

    const user = new CognitoUser({ Username: username, Pool: this.userPool });
    const details = new AuthenticationDetails({
      Username: username,
      Password: password,
      ClientMetadata: clientMetadata,
    });

    return new Promise((res, rej) => {
      user.authenticateUser(details, {
        onSuccess: (session, needsConfirmed) => {
          console.debug('user signed in', { session, needsConfirmed });
          return res(user);
        },
        onFailure: rej,
      });
    });
  }

  signOut(): Promise<void> {
    return new Promise((res, rej) => {
      if (this.user != null) {
        return this.user.signOut(res);
      }
      return rej();
    });
  }

  getCurrentSession(): Promise<CognitoUserSession | null> {
    return new Promise((res, rej) => {
      if (this.user == null) {
        return res(null);
      }
      this.user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err != null) {
          return rej(err);
        }
        return res(session);
      });
    });
  }

  async forceRefreshSession(): Promise<CognitoUserSession> {
    const session = await this.getCurrentSession();
    return new Promise((res, rej) => {
      if (session == null || this.user == null) {
        throw new Error('no session to refresh');
      }
      this.user.refreshSession(session.getRefreshToken(), (err, session) => {
        if (err != null) {
          return rej(err);
        }
        return res(session);
      });
    });
  }

  confirmSignUp(username: string, code: string): Promise<void> {
    const user = new CognitoUser({ Username: username, Pool: this.userPool });
    return new Promise((res, rej) =>
      user.confirmRegistration(code, true, (err) => {
        if (err != null) {
          return rej(err);
        }
        return res();
      }),
    );
  }
}
