import { Box, Container, Link, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import loginWave from '../../images/loginWave.png';
import reefHeaderLogo from '../../images/reef_logo_color.svg';

export interface SplashPageProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
}

export const SplashPage = ({
  title,
  description,
  children,
}: PropsWithChildren<SplashPageProps>) => {
  return (
    <Container>
      <Box
        sx={{ minHeight: '100%', position: 'fixed', top: 0, right: 0, display: 'flex', zIndex: -1 }}
      >
        <img src={loginWave} />
      </Box>
      <Box sx={{ pt: 6 }}>
        <Box sx={{ pb: 4 }}>
          <Link component={RouterLink} to="/" sx={{ cursor: 'pointer' }}>
            <img src={reefHeaderLogo} />
          </Link>
        </Box>
        <Typography variant="h2" color="primary">
          {title}
        </Typography>
        <Typography sx={{ pt: 3, pb: 2 }}>{description}</Typography>
      </Box>
      {children}
    </Container>
  );
};
