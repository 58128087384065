import { Typography } from '@mui/material';
import React from 'react';

export interface SubtitleProps {
  children: string;
}

export const Subtitle = ({ children }: SubtitleProps) => (
  <Typography
    variant="h5"
    sx={{ color: (theme) => theme.palette.primary.main, fontWeight: 500, marginTop: '1.5rem' }}
  >
    {children}
  </Typography>
);
