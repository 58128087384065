import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';

import reefLogo from '../../../images/reef_logo.webp';
import { CustomerServiceLink } from './CustomerServiceLink';
import { CustomList } from './CustomList';
import { Subtitle } from './Subtitle';

export const OktaConfigurationGuide = () => {
  return (
    <Stack direction="row">
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          width: '20px',
          minHeight: '100vh',
        }}
      ></Box>
      <Box sx={{ margin: '20px', paddingLeft: '20px', maxWidth: '1000px' }}>
        <Box
          component="img"
          sx={{
            width: 150,
            height: 'auto',
            marginBottom: '50px',
            color: (theme) => theme.palette.primary.main,
          }}
          src={reefLogo}
        />
        <Typography
          variant="h3"
          sx={{ color: (theme) => theme.palette.primary.main, marginBottom: '30px' }}
        >
          Okta Configuration Guide
        </Typography>
        <Typography variant="h6" sx={{ margin: '15px 0' }}>
          Use the following steps to set up your organization to access Reef.ai:
        </Typography>

        <Subtitle>Requirements</Subtitle>

        <CustomList listName="requirements" list={[<>Admin access to your Okta organization</>]} />

        <Subtitle>Configuration Steps</Subtitle>

        <CustomList
          listName="configuration-steps"
          listIcon={null}
          list={[
            <>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                1. Create an App Integration in Okta
              </Typography>
              <CustomList
                listName="create-app"
                list={[
                  <>
                    Navigate to <strong>Applications → Create App Integration</strong> in your Okta
                    Admin Console.
                  </>,
                  <>
                    In the dialog box:
                    <CustomList
                      listName="dialog-box"
                      list={[
                        <>
                          Select <strong>OIDC</strong> as the <strong>Sign-in method</strong>.
                        </>,
                        <>
                          Select <strong>Web Application</strong> as the{' '}
                          <strong>Application type</strong>.
                        </>,
                        <>
                          Click <strong>Next</strong>.
                        </>,
                      ]}
                    />
                  </>,
                ]}
              />
            </>,
            <>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                2. Configure General Settings
              </Typography>
              <CustomList
                listName="general-settings"
                list={[
                  <>
                    Provide a name for your application (e.g., <strong>Reef.ai</strong>).
                  </>,
                  <>
                    Set the <strong>Sign-in redirect URI</strong> to{' '}
                    <code>https://auth.reef.ai/oauth2/idpresponse</code>.
                  </>,
                  <>
                    Configure <strong>Assignments</strong> to specify the groups or users who will
                    have access to <strong>Reef.ai</strong>.
                  </>,
                ]}
              />
            </>,
            <>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                3. Gather Integration Details
              </Typography>
              <CustomList
                listName="integration-details"
                list={[
                  <>
                    Once the app is created, <CustomerServiceLink text="contact" /> Reef.ai support
                    with the following information:
                    <CustomList
                      listName="needed-information"
                      list={[
                        <>Client ID</>,
                        <>Client Secret</>,
                        <>
                          OpenID Connect Issuer URL: This is the URL of the discovery document of
                          the OpenID Connect provider you want to connect with. That can also be
                          found in <strong>Security → API → Authorization Servers</strong>
                        </>,
                      ]}
                    />
                  </>,
                ]}
              />
            </>,
            <>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                4. Confirm Integration
              </Typography>
              <CustomList
                listName="confirm-integration"
                list={[
                  <>
                    Reef.ai&apos;s support team will verify your setup and confirm once you can sign
                    in to Reef.ai using Okta Single Sign-On (SSO).
                  </>,
                ]}
              />
            </>,
          ]}
        />

        <Subtitle>Logging Into Reef.ai</Subtitle>

        <Typography variant="body1" sx={{ marginTop: '15px' }}>
          Once the Okta SSO is set up, users with an active Reef.ai account can log in as follows:
        </Typography>

        <CustomList
          listName="login-steps"
          list={[
            <>
              Go to{' '}
              <Link href="https://app.reef.ai" target="_blank" rel="noopener noreferrer">
                https://app.reef.ai
              </Link>
            </>,
            <>
              Enter your Okta email address and click <strong>Log In</strong>
            </>,
            <>Sign in with Okta</>,
          ]}
        />

        <Typography variant="body1" sx={{ margin: '15px 0' }}>
          If you have any questions, please <CustomerServiceLink text="reach out" />!
        </Typography>
      </Box>
    </Stack>
  );
};
