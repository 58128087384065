import CircleIcon from '@mui/icons-material/Circle';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';

export interface CustomListProps {
  list: JSX.Element[];
  listIcon?: ReactNode;
  listName: string;
}

export const CustomList = ({
  list,
  listIcon = <CircleIcon sx={{ fontSize: 10, color: 'black' }} />,
  listName,
}: CustomListProps) => (
  <List
    sx={{
      '& .MuiListItemIcon-root': { paddingTop: '10px' },
      '& .MuiListItem-root': {
        alignItems: 'flex-start',
      },
    }}
    disablePadding
  >
    {list.map((ele, index) => {
      return (
        <ListItem key={`okta-config-guide-${listName}-${index}`}>
          {listIcon && <ListItemIcon sx={{ minWidth: 20 }}>{listIcon}</ListItemIcon>}
          <ListItemText>{ele}</ListItemText>
        </ListItem>
      );
    })}
  </List>
);
